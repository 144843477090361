import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Loading from './components/Loading';

const Home = lazy(() => import('./pages/Home'));
// const Products = lazy(() => import('./pages/Products'));
const Classes = lazy(() => import('./pages/Classes'));
const DollStories = lazy(() => import('./pages/DollStories'));
const GarysTips = lazy(() => import('./pages/GarysTips'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const AskGary = lazy(() => import('./pages/AskGary'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Contact = lazy(() => import('./pages/Contact'));
const DollRestoration = lazy(() => import('./pages/DollRestoration'));

const App = () => (
    <Router>
        <Navbar />
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/products" element={<Products />} /> */}
                <Route path="/classes" element={<Classes />} />
                <Route path="/doll-stories" element={<DollStories />} />
                <Route path="/garys-tips" element={<GarysTips />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/ask-gary" element={<AskGary />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/doll-restoration" element={<DollRestoration />} />
            </Routes>
        </Suspense>
        <Footer />
    </Router>
);

export default App;
