import React from 'react';
import { Typography, Container } from '@mui/material';

const Footer = () => (
    <Container component="footer" sx={{ py: 3, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
            &copy; 2012 by Sowatzka's Dolls. All rights reserved.
        </Typography>
    </Container>
);

export default Footer;
