import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Box
                    component="img"
                    sx={{
                        height: 100, // Adjust the size as necessary
                        marginRight: 2,
                    }}
                    alt="Logo"
                    src="/images/logo.png" // Correct path to the logo
                />
                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                    <Button color="inherit" component={Link} to="/">Home</Button>
                    {/* <Button color="inherit" component={Link} to="/products">Products</Button> */}
                    <Button color="inherit" component={Link} to="/classes">Classes</Button>
                    <Button color="inherit" component={Link} to="/garys-tips">Gary's Tips</Button>
                    <Button color="inherit" component={Link} to="/testimonials">Testimonials</Button>
                    <Button color="inherit" component={Link} to="/about-us">About Us</Button>
                    <Button color="inherit" component={Link} to="/contact">Contact</Button>
                </Box>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
                    {/* <MenuItem onClick={handleClose} component={Link} to="/products">Products</MenuItem> */}
                    <MenuItem onClick={handleClose} component={Link} to="/classes">Classes</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/garys-tips">Gary's Tips</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/testimonials">Testimonials</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/about-us">About Us</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/contact">Contact</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
